<template>
  <div class="w-100 justify-content-center d-flex">
    <div class="text-center mt-8">
      <img src="/img/no-data.png" alt="no data found" />
      <h2 class="text-h3 font-weight-bolder mb-3 mt-5">Not found</h2>
      <p class="text-h6">
        Dashboard is not configured for selected warehouse yet.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoDataFound",
};
</script>
