<template>
  <div class="m-2" v-if="serverData">
    <v-card
      class="rounded-md mb-3 mb-xl-0 shadow-none position-relative"
      @click="navigateToOrders"
    >
      <v-card-text :class="['px-6 pt-6 pb-0', { 'pb-6': !lineChart }]">
        <div class="mb-2 d-flex justify-content-between">
          <p class="font-size-h4 font-weight-medium mt-3 text-dark-50">
            {{ serverData.title }}
          </p>
          <img
            v-if="serverData.icon"
            :src="serverData.icon"
            alt="icon"
            class="w-35px mb-2"
          />
          <!-- <div class="mt-1 d-flex">
            <div class="font-size-h5 font-weight-bolder green--text">+22%</div>
            <div
              class="font-size-h2 font-weight-bolder green--text mb-5 ml-1"
              style="transform: rotate(273deg)"
            >
              >
            </div>
          </div> -->
        </div>

        <div>
          <h1 class="title-counter text-dark-65 mb-0">
            {{ serverData.values }}
          </h1>
          <p class="font-size-lg">{{ serverData.caption }}</p>
        </div>
        <template v-if="additionalIfo">
          <div v-if="serverData.additional" class="pb-4 d-flex">
            <div
              class="bullet w-8px h-6px rounded-2 bg-primary me-3 mt-2"
            ></div>
            <p
              class="text-dark-65 mb-0 font-size-h6 font-weight-medium mr-7 text-counter-card-caption"
            >
              {{ serverData.additional.title }}
            </p>
            <p class="font-size-lg font-size-h6 font-weight-boldest">
              {{ serverData.additional.value }}
            </p>
          </div>
        </template>
      </v-card-text>
      <apexchart
        v-if="lineChart"
        type="area"
        height="100"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </v-card>
  </div>
  <v-skeleton-loader v-else type="card"></v-skeleton-loader>
</template>

<script>
// import { getToken } from "@/core/services/jwt.service";
// import axios from "axios";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { SET_TABLE_FILTER } from "@/core/services/store/orders.module";
import ordersFilterMixin from "@/own/mixins/ordersFilterMixin";
import { SET_FILTER_BUTTON_DISABLED } from "@/core/services/store/warehouseDashboard.module";

// import Swal from "sweetalert2";
export default {
  name: "FulfillmentOrdersCounterChart",
  props: {
    item: { required: true },
    filterData: { required: true },
    index: { required: true },
    lineChart: { required: true },
    additionalIfo: { required: true },
    updateChart: { required: true },
  },
  mixins: [ordersFilterMixin],
  data: () => ({ serverData: null }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.loadDataFromServer();
  },
  methods: {
    loadDataFromServer() {
      this.serverData = null;
      let data = this.filterData;
      ApiService.post(`${this.item.url}`, data)
        .then((response) => {
          this.serverData = response.data.chart;
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1000);
        })
        .catch(() => {})
        .finally(() => {
          this.$store.commit(SET_FILTER_BUTTON_DISABLED, false);
        });
    },
    navigateToOrders() {
      const status = this.getOrderStatus(this.item.name);
      if (status) {
        this.$store.commit(SET_PAGE_LOADING, true);

        const FILTERS = [];
        let range = null;

        if (this.filterData.date_range_type) {
          range = {
            name: "date_range.date",
            value: this.filterData.date_range,
          };
        }
        FILTERS.push(range);
        FILTERS.push(status);
        FILTERS.push({
          name: "date_range.select",
          value: this.serverData.datarangetype,
        });
        if (this.filterData.client) {
          FILTERS.push({ name: "client", value: [this.filterData.client] });
        }
        if (this.filterData.carrier) {
          FILTERS.push({ name: "carrier", value: [this.filterData.carrier] });
        }
        this.$store.commit(SET_TABLE_FILTER, FILTERS);
        this.$router.push("/fulfillment/orders");
      }
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.serverData;
    },
    chartOptions: function () {
      return {
        chart: {
          height: "100",
          type: "area",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: [this.colorShade], // shade of line
        // colors: ["#F64E60"],
        stroke: {
          curve: "smooth",
          show: true,
          width: 3,
        },
        xaxis: {
          type: "category",
          // categories: this.serverData.categories,
          categories: [1, 2, 3, 4, 5],
          labels: {
            show: true,
            formatter: function (val) {
              const secondDigit = /^\d+$/.test(String(val).slice(1, 2))
                ? String(val).slice(1, 2)
                : "";

              return (
                String(val).slice(-3) +
                " " +
                String(val).slice(0, 1) +
                secondDigit
              );
            },
            style: {
              colors: "#b1b5c3",
              fontSize: "12px",
            },
          },
          crosshairs: {
            show: false,
            position: "front",
            stroke: {
              width: 1,
              dashArray: 3,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
            style: {
              colors: ["#afb2c1"],
              fontSize: "12px",
              fontWeight: 500,
            },
          },
        },
        fill: {
          // colors: this.colorShade,
          colors: this.colorShade, // the line color
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.9,
            opacityFrom: 0.8,
            opacityTo: 0,
            stops: [0, 90],
            colorStops: [],
          },
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            // format: "dd MMM",
            formatter: (formatter) => this.serverData.categories[formatter - 1],
          },
          y: {
            formatter: function (value) {
              return value;
            },
            title: {
              formatter: () =>
                this.item.title === "Total sales" ? "Total sales:" : "Orders:",
            },
          },
        },
      };
    },
    series: function () {
      return [
        {
          name: "Orders",
          data: this.serverData.series[0].data,
        },
      ];
    },
    colorShade: function () {
      const data = this.serverData.series[0].data;
      if (data[0] < data[data.length - 1]) {
        return "#28a745";
      }
      if (data[0] === data[data.length - 1]) {
        return "#ffff4d";
      }
      return "#F64E60";
    },
  },
  watch: {
    // filterData() {
    //   this.serverData = null;
    //   this.loadDataFromServer();
    // },
    updateChart: function (val) {
      if (val !== 0) {
        this.loadDataFromServer();
      }
    },
  },
};
</script>

<style scoped>
.border-circular {
  border-radius: 12px;
}
.title-counter {
  font-size: 3rem;
}
.rounded-md {
  border-radius: 0.6rem;
  box-shadow: rgba(198, 198, 202, 0.25) 0px 2px 10px 0px !important;
}
.icon-md {
  font-size: 2rem !important;
}
.text-dark-55 {
  color: #d1d1da;
}
</style>
