<template>
  <div v-if="filter.type === 'autocompSingle'">
    <v-autocomplete
      class="px-2 py-1 filter-input"
      dense
      v-model="filterData[`${filter.name}`]"
      :items="filter.values"
      item-text="text"
      item-value="index"
      :label="filter.title.en"
      @change="handleChange"
      single-line
      hide-details
      solo
    >
      <template v-slot:prepend-inner>
        <inline-svg class="px-1" width="26" :src="filter.icon"></inline-svg>
      </template>
      <template v-slot:item="{ item }"
        ><div
          style="display: flex; justify-content: center; align-items: center"
        >
          <div>{{ item.text }}</div>
        </div>
      </template>
    </v-autocomplete>
  </div>
  <div v-else-if="filter.type === 'select2Single'">
    <v-autocomplete
      class="px-2 py-1 filter-input"
      dense
      v-model="filterData[`${filter.name}`]"
      :items="filter.values"
      item-text="text"
      item-value="index"
      :label="filter.title.en"
      @change="handleChange"
      solo
      single-line
      hide-details
    >
      <template v-slot:prepend-inner>
        <inline-svg class="px-1" width="26" :src="filter.icon"></inline-svg>
      </template>
      <template v-slot:item="{ item }">
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <div>{{ item.text }}</div>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "FilterType.vue",
  props: ["filter", "updateFunction"],
  data: () => ({
    filterData: {},
  }),
  methods: {
    handleChange() {
      this.updateFunction(this.filterData);
    },
  },
};
</script>
